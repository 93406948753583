import { createRouter, createWebHashHistory } from 'vue-router'
import store from './store/index'
import DashboardLayout from '@/layouts/DashboardLayout'
import { getMe } from '@/api'
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/LarkLogin.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/auth',
    component: () => import('@/views/login/Auth.vue')
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'DashboardLayout',
    component: DashboardLayout,
    meta: {
      breadcrumb: '首页',
      requireLogin: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/FinDashboard.vue'),
        meta: {
          breadcrumb: 'Dashboard',
          title: 'Dashboard',
          requireLogin: true
        }
      },
      {
        path: 'payin',
        name: 'PayIn',
        component: () => import('@/views/payin/index.vue'),
        meta: {
          breadcrumb: 'PayIn',
          title: 'PayIn',
          requireLogin: true
        },
        children: [
          {
            path: 'details',
            name: 'Details',
            component: () => import('@/views/payin/details/index.vue'),
            meta: {
              breadcrumb: 'Details',
              title: 'Details',
              requireLogin: true
            },
            children: [
              {
                path: 'paypal',
                name: 'Paypal Transactions',
                component: () => import('@/views/payin/details/paypal.vue'),
                meta: {
                  breadcrumb: 'Paypal',
                  title: 'Paypal',
                  requireLogin: true
                }
              },
              {
                path: 'stripe',
                name: 'Stripe Transactions',
                component: () => import('@/views/payin/details/stripe.vue'),
                meta: {
                  breadcrumb: 'Stripe',
                  title: 'Stripe',
                  requireLogin: true
                }
              }
            ]
          },
          {
            path: 'balances',
            name: 'Balances',
            component: () => import('@/views/payin/balances/index.vue'),
            meta: {
              breadcrumb: 'Balances',
              title: 'Balances',
              requireLogin: true
            },
            children: [
              {
                path: 'paypal',
                name: 'Paypal Balances',
                component: () => import('@/views/payin/balances/paypal.vue'),
                meta: {
                  breadcrumb: 'Paypal',
                  title: 'Paypal',
                  requireLogin: true
                }
              },
              {
                path: 'stripe',
                name: 'Stripe Balances',
                component: () => import('@/views/payin/balances/stripe.vue'),
                meta: {
                  breadcrumb: 'Stripe',
                  title: 'Stripe',
                  requireLogin: true
                }
              }
            ]
          },
          {
            path: 'iap',
            name: 'Iap',
            component: () => import('@/views/payin/iap/index.vue'),
            meta: {
              breadcrumb: 'Iap',
              title: 'Iap',
              requireLogin: true
            },
            children: [
              {
                path: 'summary',
                name: 'Iap Summary',
                component: () => import('@/views/payin/iap/summary.vue'),
                meta: {
                  breadcrumb: '汇总',
                  title: '汇总',
                  requireLogin: true
                }
              },
              {
                path: 'purchase',
                name: 'Purchase',
                component: () => import('@/views/payin/iap/purchase.vue'),
                meta: {
                  breadcrumb: '内购',
                  title: '内购',
                  requireLogin: true
                }
              },
              {
                path: 'subscribe',
                name: 'Subscribe',
                component: () => import('@/views/payin/iap/subscribe.vue'),
                meta: {
                  breadcrumb: '订阅',
                  title: '订阅',
                  requireLogin: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'payout',
        name: 'Payout',
        component: () => import('@/views/payout/index.vue'),
        meta: {
          breadcrumb: 'Payout',
          title: 'Payout',
          requireLogin: true
        },
        children: [
          {
            path: 'advisors',
            name: 'Advisors',
            component: () => import('@/views/payout/advisors.vue'),
            meta: {
              breadcrumb: 'Advisors',
              title: 'Advisors',
              requireLogin: true
            }
          },
          {
            path: 'po_applys',
            name: 'PayoutApplys',
            component: () => import('@/views/payout/applys/index.vue'),
            meta: {
              breadcrumb: '提现明细',
              title: '提现明细',
              requireLogin: true
            },
            children: [
              {
                path: 'po_apply_details',
                name: 'PayoutApplysDetails',
                component: () => import('@/views/payout/applys/applys.vue'),
                meta: {
                  breadcrumb: '顾问提现明细',
                  title: '顾问提现明细',
                  requireLogin: true
                }
              },
              {
                path: 'advisorsExpenditure',
                name: 'advisorsExpenditure',
                component: () => import('@/views/payout/Expenditure.vue'),
                meta: {
                  breadcrumb: '顾问提现汇总',
                  title: '顾问提现汇总',
                  requireLogin: true
                }
              }
            ]
          },
          {
            path: 'po_details',
            name: 'PayoutDetails',
            component: () => import('@/views/payout/details/index.vue'),
            meta: {
              breadcrumb: '打款明细',
              title: '打款明细',
              requireLogin: true
            },
            children: [
              {
                path: 'po_advisors',
                name: 'PayoutAdvisorsDetails',
                component: () => import('@/views/payout/details/advisors.vue'),
                meta: {
                  breadcrumb: '打款明细',
                  title: '派安盈打款',
                  requireLogin: true
                }
              }
            ]
          },
          {
            path: 'po_binds',
            name: 'PayoutBinds',
            component: () => import('@/views/payout/binds/index.vue'),
            meta: {
              breadcrumb: '绑定详情',
              title: '绑定详情',
              requireLogin: true
            },
            children: [
              {
                path: 'po_payoneer_binds',
                name: 'PayoutPayoneerBinds',
                component: () => import('@/views/payout/binds/payoneer.vue'),
                meta: {
                  breadcrumb: '派安盈绑定顾问',
                  title: '派安盈绑定顾问',
                  requireLogin: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'systems',
        name: 'System',
        component: () => import('@/views/systems/index.vue'),
        meta: {
          breadcrumb: '管理',
          title: 'System',
          requireLogin: true
        },
        children: [
          {
            path: 'users',
            name: 'Users',
            component: () => import('@/views/systems/users.vue'),
            meta: {
              breadcrumb: 'Users',
              title: 'Users',
              requireLogin: true
            }
          },
          {
            path: 'accounts',
            name: 'Accounts',
            component: () => import('@/views/systems/accounts.vue'),
            meta: {
              breadcrumb: 'Accounts',
              title: 'Accounts',
              requireLogin: true
            }
          },
          {
            path: 'permissions',
            name: 'Permissions',
            component: () => import('@/views/systems/permissions.vue'),
            meta: {
              breadcrumb: 'Permissions',
              title: 'Permissions',
              requireLogin: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFind',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404'
    }
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// // 页面刷新时，重新赋值token
// if (localStorage.getItem('token')) {
//   store.commit('BIND_LOGIN', localStorage.getItem('token'))
// }

// 全局导航钩子
router.beforeEach(async (to, from, next) => {
  var tokenInStorage = localStorage.getItem('token')
  var tokenInCookie = Cookies.get('x-fin-token')
  if (
    (tokenInCookie || tokenInStorage) &&
    to.path != '/login' &&
    to.path != '/auth'
  ) {
    let res = await getMe()
    let user = res.data.user
    let permission = res.data.permission
    console.log('get me: ', user, permission)
    if (!user) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
      return
    }
    store.dispatch('saveUser', user)
    store.dispatch('savePermission', permission)
    next()
  } else {
    if (to.meta.title) {
      // 路由发生变化修改页面title
      document.title = to.meta.title
    }
    if (to.meta.requireLogin) {
      if (tokenInCookie) {
        if (Object.keys(from.query).length === 0) {
          // 判断路由来源是否有query，处理不是目的跳转的情况
          next()
        } else {
          let redirect = from.query.redirect // 如果来源路由有query
          if (to.path === redirect) {
            // 避免 next 无限循环
            next()
          } else {
            next({
              path: redirect
            }) // 跳转到目的路由
          }
        }
      } else {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          } // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }
    } else {
      if (store.getters.token && to.path === '/login') {
        next({ path: '/' })
      } else {
        next()
      }
    }
  }
})

export default router
