const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  username: (state) => state.user.username,
  connected: (state) => state.app.connected,
  connection: (state) => state.app.connection,
  // user
  user: (state) => state.user.user,
  permissions: (state) => state.user.permission,
  // advisor payout
  paydialog: (state) => state.payout.payoutDialogOpened,
  payout: (state) => state.payout.payout,
  payoutConfirmType: (state) => state.payout.confirmType,
  payoutPayType: (state) => state.payout.payType,
  // account
  accountDialog: (state) => state.account.accountDialogOpened,
  // permission
  permissionDialog: (state) => state.permission.permissionDialogOpened,
  permissionData: (state) => state.permission.permissionData
}
export default getters
