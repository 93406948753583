const payout = {
  state: {
    payoutDialogOpened: false,
    payType: 'paypal',
    confirmType: 'pay',
    payout: null
  },
  mutations: {
    OPEN_PAY_DIALOG: (state) => {
      state.payoutDialogOpened = true
    },
    CLOSE_PAY_DIALOG: (state) => {
      state.payoutDialogOpened = false
    },
    SET_PAY_TYPE: (state, type) => {
      state.payType = type
    },
    SET_CONFIRM_TYPE: (state, type) => {
      state.confirmType = type
    },
    SET_PAYOUT: (state, data) => {
      state.payout = data
    }
  },
  actions: {
    OpenPayDialog: ({ commit }) => {
      commit('OPEN_PAY_DIALOG')
    },
    ClosePayDialog: ({ commit }) => {
      commit('CLOSE_PAY_DIALOG')
    },
    SetPayOut: ({ commit }, data) => {
      commit('SET_PAY_TYPE', data.payType)
      commit('SET_CONFIRM_TYPE', data.confirmType)
      commit('SET_PAYOUT', data.payout)
    }
  }
}

export default payout
