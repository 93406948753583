<template>
  <div class="sidebar">
    <el-menu
      :default-active="activeIndex"
      router
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :default-openeds="defaultOpeneds"
    >
      <el-sub-menu
        v-if="permissions.PayInView"
        :collapse="false"
        index="/payin"
      >
        <template #title>
          <el-icon>
            <Wallet />
          </el-icon>
          <span>收入</span>
        </template>
        <el-sub-menu
          v-if="permissions.PayInIapView"
          :collapse="false"
          index="/iap"
        >
          <template #title>
            <el-icon><Money /></el-icon>
            <span>充值明细</span>
          </template>
          <el-menu-item
            v-if="permissions.SummaryView"
            index="/payin/iap/summary"
            >汇总</el-menu-item
          >
          <el-menu-item index="/payin/iap/purchase">内购</el-menu-item>
          <el-menu-item index="/payin/iap/subscribe">订阅</el-menu-item>
        </el-sub-menu>
        <el-sub-menu
          v-if="permissions.PayInBalanceView"
          :collapse="false"
          index="/balance"
        >
          <template #title>
            <el-icon><Files /></el-icon>
            <span>余额明细</span>
          </template>
          <el-menu-item v-if="false" index="/payin/balances/payoneer"
            >Payoneer</el-menu-item
          >
          <el-menu-item v-if="false" index="/payin/balances/pingpong"
            >PingPong</el-menu-item
          >
          <el-menu-item index="/payin/balances/paypal">Paypal</el-menu-item>
          <el-menu-item index="/payin/balances/stripe">Stripe</el-menu-item>
          <el-menu-item v-if="false" index="/payin/balances/apple"
            >Apple</el-menu-item
          >
          <el-menu-item v-if="false" index="/payin/balances/google"
            >Google</el-menu-item
          >
        </el-sub-menu>
        <el-sub-menu
          v-if="permissions.PayInDetailView"
          :collapse="false"
          index="/details"
        >
          <template #title>
            <el-icon><Document /></el-icon>
            <span>流水明细</span>
          </template>
          <el-menu-item v-if="false" index="/payin/details/payoneer"
            >Payoneer</el-menu-item
          >
          <el-menu-item v-if="false" index="/payin/details/pingpong"
            >PingPong</el-menu-item
          >
          <el-menu-item v-if="false" index="/payin/details/apple"
            >Apple</el-menu-item
          >
          <el-menu-item v-if="false" index="/payin/details/google"
            >Google</el-menu-item
          >
          <el-menu-item index="/payin/details/paypal">Paypal</el-menu-item>
          <el-menu-item index="/payin/details/stripe">Stripe</el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
      <el-sub-menu
        v-if="permissions.PayoutView"
        :collapse="false"
        index="/payout"
      >
        <template #title>
          <el-icon>
            <CreditCard />
          </el-icon>
          <span>顾问提现</span>
        </template>
        <el-menu-item index="/payout/advisors">
          <template #title>
            <el-icon><Warning /></el-icon>
            <span>顾问提现申请</span>
          </template>
        </el-menu-item>

        <el-sub-menu v-if="true" :collapse="false" index="/payout/po_applys">
          <template #title>
            <el-icon><Money /></el-icon>
            <span>提现明细</span>
          </template>
          <el-menu-item index="/payout/po_applys/advisorsExpenditure">
            顾问提现汇总
          </el-menu-item>
          <el-menu-item v-if="true" index="/payout/po_applys/po_apply_details"
            >顾问提现明细</el-menu-item
          >
        </el-sub-menu>
        <el-sub-menu v-if="true" :collapse="false" index="/payout/po_details">
          <template #title>
            <el-icon><Money /></el-icon>
            <span>打款明细</span>
          </template>
          <el-menu-item v-if="true" index="/payout/po_details/po_advisors"
            >派安盈打款</el-menu-item
          >
        </el-sub-menu>
        <el-sub-menu v-if="true" :collapse="false" index="/payout/po_binds">
          <template #title>
            <el-icon><User /></el-icon>
            <span>账号绑定</span>
          </template>
          <el-menu-item v-if="true" index="/payout/po_binds/po_payoneer_binds"
            >派安盈</el-menu-item
          >
        </el-sub-menu>
      </el-sub-menu>
      <el-sub-menu
        v-if="permissions.SystemView"
        :collapse="false"
        index="/systems"
      >
        <template #title>
          <el-icon>
            <Tools />
          </el-icon>
          <span>管理</span>
        </template>
        <el-menu-item v-if="permissions.SystemUsersView" index="/systems/users"
          >用户管理</el-menu-item
        >
        <el-menu-item
          v-if="permissions.SystemAccountsView"
          index="/systems/accounts"
          >支付账户</el-menu-item
        >
        <el-menu-item
          v-if="permissions.SystemPermissionsView"
          index="/systems/permissions"
          >权限管理</el-menu-item
        >
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyStorage from '@/utils/cache'
export default {
  name: 'Sidebar',
  data() {
    return {
      myPermissionList: [],
      defaultOpeneds: [
        '/payin',
        '/payout',
        '/systems',
        '/iap',
        '/balance',
        '/details'
      ]
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'permissions']),
    isCollapse() {
      return !this.sidebar.opened
    },
    activeIndex() {
      const thisRoutPath = this.$route.path
      if (this.$route.name === 'details') {
        return '/tables/details'
      }
      return thisRoutPath
    }
  },
  created() {
    const permissions = MyStorage.getItem('permissions') || {},
      arr = [],
      myPermissions = JSON.parse(localStorage.getItem('myPermissions')) || []
    Object.keys(permissions).forEach((key) => {
      if (myPermissions.includes(permissions[key])) {
        arr.push(key)
      }
    })
    this.myPermissionList = arr
  },
  methods: {
    blankUrl(url) {
      let routeUrl = this.$router.resolve({
        path: url
      })
      window.open(routeUrl.href, '_blank')
      // this.$router.push('/data/user/order')
    }
  }
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.el-menu-vertical-demo {
  height: 100%;
}

@media all and (max-width: 768px) {
  .el-menu--collapse {
    width: 0;
    overflow: hidden;
  }
}
</style>
