import * as CryptoJS from 'crypto-js'

const secrectKey = '9g8ndXVCDLxUc3rF'
// const cryptAlgorithm = 'aes-128-ecb'

export default function decryptUsingAES128(str) {
  let _key = CryptoJS.enc.Utf8.parse(secrectKey)
  let _iv = CryptoJS.enc.Utf8.parse(secrectKey)

  return CryptoJS.AES.decrypt(str, _key, {
    keySize: 16,
    iv: _iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString(CryptoJS.enc.Utf8)
}

export function test(str) {
  return decryptUsingAES128(str)
}
