const permission = {
  state: {
    permissionDialogOpened: false,
    permissionData: null
  },
  mutations: {
    OPEN_ACCOUNT_DIALOG: (state) => {
      state.permissionDialogOpened = true
    },
    CLOSE_ACCOUNT_DIALOG: (state) => {
      state.permissionDialogOpened = false
    },
    SET_PERMISSION: (state, data) => {
      state.permissionData = data
    }
  },
  actions: {
    OpenPermissionDialog: ({ commit }) => {
      commit('OPEN_ACCOUNT_DIALOG')
    },
    ClosePermissionDialog: ({ commit }) => {
      commit('CLOSE_ACCOUNT_DIALOG')
    },
    SetPermissionData: ({ commit }, data) => {
      commit('SET_PERMISSION', data)
    },
  }
}

export default permission