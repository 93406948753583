// axios 配置
import axios from 'axios'
import store from '../store'
import md5 from 'js-md5'
import decryptUsingAES128 from '../utils/Crypt'
const KEY = 'E318970D1196'
import router from '../router'
import { notify } from '@kyvg/vue3-notification'
import Cookies from 'js-cookie'
//创建 axios 实例

let baseUrl = process.env.VUE_APP_BASE_URL
let instance = axios.create({
  baseURL: baseUrl,
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'user-info': 'answer-planet_web_v1.0',
    'x-fin-token': `${
      store.getters.token ||
      localStorage.getItem('token') ||
      Cookies.get('x-fin-token')
    }` // local admin Token
  }
})

instance.interceptors.request.use(
  (config) => {
    let param = {}
    if (config.method.toLowerCase() == 'get') {
      //参数不为空时
      if (config.params) {
        param = config.params
        for (let i in param) {
          param[i] = param[i].toString()
        }
      } else {
        param = {}
      }
    } else {
      if (config.data) {
        param = config.data
      } else {
        param = {}
      }
    }
    // console.log(config)
    console.log('gen sig from param', param)
    const time = new Date().getTime()

    config.headers['x-signature'] = md5(
      JSON.stringify(param).replace(/\s+/g, '') + time + KEY
    )
    //时间戳
    config.headers['x-timestamp'] = time
    if (store.getters.token) {
      // 若存在token，则每个Http Header都加上token
      config.headers['x-token'] = `${store.getters.token}`
      config.headers['x-fin-token'] = `${store.getters.token}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

// http response 拦截器
instance.interceptors.response.use(
  (response) => {
    // console.log(response)
    // localStorage.setItem('token', response.headers['x-token'])
    const data = response.data['d']
      ? JSON.parse(decryptUsingAES128(response.data['d']))
      : response
    // if (response.data['d']) {
    //   return JSON.parse(decryptUsingAES128(response.data['d']))
    // }
    // console.log(data)
    return data
  },

  (error) => {
    if (error.response) {
      if (error.response.data.code === 10005) {
        store.commit('BIND_LOGOUT')
        router.push('/login')
        return
      }
      switch (error.response.status) {
        case 400:
          notify({
            title: 'Error',
            message: error.response.data.msg,
            type: 'error'
          })
          break
        default:
          // alert('服务器出错，请稍后重试！')
          notify({
            title: 'Error',
            message: '服务器内部错误，请联系管理员',
            type: 'error'
          })
      }
    } else {
      notify({
        title: 'Error',
        message: '服务器内部错误，请联系管理员',
        type: 'error'
      })
    }
    return Promise.reject(error.response) // 返回接口返回的错误信息
  }
)
export default instance

// }
