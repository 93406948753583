const account = {
  state: {
    accountDialogOpened: false
  },
  mutations: {
    OPEN_ACCOUNT_DIALOG: (state) => {
      state.accountDialogOpened = true
    },
    CLOSE_ACCOUNT_DIALOG: (state) => {
      state.accountDialogOpened = false
    }
  },
  actions: {
    OpenAccountDialog: ({ commit }) => {
      commit('OPEN_ACCOUNT_DIALOG')
    },
    CloseAccountDialog: ({ commit }) => {
      commit('CLOSE_ACCOUNT_DIALOG')
    }
  }
}

export default account
