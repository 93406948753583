const user = {
  state: {
    token: localStorage.getItem('token'),
    user: null,
    permission: null
  },

  mutations: {
    BIND_LOGIN: (state, data) => {
      localStorage.setItem('token', data)
      state.token = data
    },
    BIND_LOGOUT: (state) => {
      localStorage.removeItem('token')
      state.token = null
    },
    SAVE_USER: (state, data) => {
      localStorage.setItem('user', data)
      state.user = data
    },
    SAVE_PERMISSION: (state, data) => {
      localStorage.setItem('permission', data)
      state.permission = data
    }
  },

  actions: {
    bindLogin({ commit }, data) {
      commit('BIND_LOGIN', data)
    },
    bindLogout({ commit }) {
      commit('BIND_LOGOUT')
    },
    saveUser({ commit }, data) {
      commit('SAVE_USER', data)
    },
    savePermission({ commit }, data) {
      commit('SAVE_PERMISSION', data)
    }
  }
}

export default user
