<template>
  <div class="sl-body">
    <el-header>
      <Header></Header>
    </el-header>
    <div class="main-bottom">
      <Siderbar></Siderbar>
      <el-container>
        <el-main>
          <Breadcrumb></Breadcrumb>
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </el-main>
        <!--el-footer>
          <Footer></Footer>
        </el-footer-->
      </el-container>
    </div>
    <Scrolltotop></Scrolltotop>
  </div>
</template>

<script>
import Header from '../components/header/Header'
import Breadcrumb from '../components/breadcrumb/Breadcrumb'
import Footer from '../components/footer/Footer'
import Siderbar from '../components/sidebar/FinSidebar'
import Scrolltotop from '../components/scrolltotop/Scrolltotop'
import ResizeMixin from '@/utils/resizeHandler'
import { mapMutations } from 'vuex'
import Cookies from 'js-cookie'
import watermark from 'watermark-package'
import { getMe } from '@/api'

export default {
  name: 'DashboardLayout',
  components: {
    Header,
    Breadcrumb,
    Footer,
    Siderbar,
    Scrolltotop
  },
  mixins: [ResizeMixin],
  computed: {},
  methods: {
    ...mapMutations({
      bindLogin: 'BIND_LOGIN',
      saveUser: 'SAVE_USER'
    }),
    async initWatermark() {
      let res = await getMe()
      let user = res.data.user
      console.log('user: ', user)
      watermark.setWaterMark({
        w_texts: [user.Name, user.Phone],
        w_options: {
          w_width: 240,
          w_height: 140,
          w_top: '10px',
          w_left: '0px',
          w_rotateDeg: 25,
          w_font: '1.0rem Vedana',
          w_color: '#666',
          w_opacity: '0.15',
          w_zIndex: '100000'
        }
      })
    }
  },
  mounted() {
    let token = localStorage.getItem('token') || Cookies.get('x-fin-token')
    if (!token) {
      this.$router.push('/login')
    } else {
      this.initWatermark(token)
    }
  },
  data: () => ({
    drawer: true,
    group: null,
    items: [
      {
        title: 'Foo',
        value: 'foo'
      },
      {
        title: 'Bar',
        value: 'bar'
      },
      {
        title: 'Fizz',
        value: 'fizz'
      },
      {
        title: 'Buzz',
        value: 'buzz'
      }
    ]
  }),
  watch: {
    group() {
      this.drawer = false
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 920px) {
  .el-main {
    padding: 0;
  }
}

.el-header,
.el-footer {
  color: #333;
  height: 50px !important;
  padding: 0;
}

.el-main {
  background-color: #f5f5f5;
  color: #333;
  display: flex;
  flex-direction: column;
}

body > .el-container {
  margin-bottom: 40px;
}

.sl-body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-bottom {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1;
}
</style>
