<template>
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="item in list" :key="item.path + item.name">
          <router-link :to="item">{{ item.meta.breadcrumb }}</router-link>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'breadCrumb',
  computed: {
    list() {
      return this.$route.matched
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumb {
  line-height: 40px;
  // background #fff
  border-radius: 4px;
  margin-bottom: 10px;

  .el-breadcrumb {
    line-height: 2;
    padding-left: 4px;
  }
}
</style>
