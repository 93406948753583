import request from './request'

//////// for light finance ///////
export function paypalPay(data) {
  return request.post('/payout/paypal', data)
}

// 飞书登陆
// 飞书扫码登录
export function loginWithFeishu(params) {
  return request.get('/webhook/lark/login', { params })
}

export function payoneerPay(data) {
  return request.post('/payout/payoneer', data)
}

export function getMe() {
  return request.get('/user/me')
}

export function getIapSummary(data) {
  return request.post('/summary/iap', data)
}

export function getStarExpenditureSummary(params) {
  return request.get('/payout/star-expenditure', { params })
}

export function getExpenditureSummary(params) {
  return request.get('/payout/expenditure-summary', { params })
}

export function getWithdrawRecord(data) {
  return request.post('/payout/records', data)
}

export function getWithdrawRecordByNos(data) {
  return request.post('/payout/records-by-withdraw-nos', data)
}

export function getWithdrawStatus(data) {
  return request.post('/payout/payoneer/status', data)
}

export function deleteWithdraw(data) {
  return request.post('/payout/record/delete', data)
}

export function getSubscribeTransactions(data) {
  return request.post('/transaction/subscribe', data)
}

export function getSubscribeApps() {
  return request.get('/transaction/subscribe/apps')
}

export function getPaypalTransactions(data) {
  return request.post('/transaction/paypal', data)
}

export function getPaypalBalances(data) {
  return request.post('/balance/paypal', data)
}

export function getPayoneerBalance(data) {
  return request.post('payoneer/program/balance', data)
}

export function getStripeTransactions(data) {
  return request.post('/transaction/stripe', data)
}

export function getStripeBalances(data) {
  return request.post('/balance/stripe', data)
}

export function deleteSystemUser(data) {
  return request.post('/system/user/delete', data)
}

export function allowSystemUser(data) {
  return request.post('/system/user/allow', data)
}

export function getSystemUsers() {
  return request.get('/system/user/')
}

export function deleteSystemAccount(data) {
  return request.post('/system/account/delete', data)
}

export function addSystemAccount(data) {
  return request.post('/system/account/add', data)
}
export function getSystemAccounts() {
  return request.get('/system/account/')
}

export function getSystemPermissions() {
  return request.get('/system/permission/')
}

export function setSystemPermission(data) {
  return request.post('/system/permission/update', data)
}

export function deleteSystemPermission(data) {
  return request.post('/system/permission/delete', data)
}

export function getPayoneerPayeeStatus(data) {
  return request.post('/payoneer/payee/status', data)
}

export function createPayoneerRegistrationLink(data) {
  return request.post('/payoneer/payee/registration-link', data)
}

export function getPayoneerRegistrationLinks(data) {
  return request.post('/payoneer/program/get-registration-links', data)
}

//////// for psychic admin ///////

//用户登录
export function userLogin(data) {
  return request.post('psychic/user/login', data)
}

// 修改用户绑定管理员账号
export function dingtalkUser(data) {
  return request.post('psychic/permission/user/bind', data)
}
// 管理员查看所有钉钉用户
// export function getDingtalkUsers() {
//   return request.get('/psychic/admin/dingtalk/users');
// }
// 管理员加金币
export function addUserCoin(data) {
  return request.post('/psychic/admin/add/user/coin', data)
}

// 管理员过滤用户
export function searchUser(data) {
  return request.post('/psychic/admin/search/users', data)
}
//获取用户信息
export function getUserInfo(data) {
  return request.get('/psychic/admin/user/who', {
    params: data
  })
}

//获取钉钉用户信息
export function ddUser(data) {
  return request.get('psychic/dd/user', {
    params: data
  })
}

//钉钉登录
// export function ddLogin(data) {
//   return request.get('/dd/login', {
//     params: data
//   });
// }

// 管理员加珍珠
export function addPearl(data) {
  return request.post('/psychic/admin/add/star/pearl', data)
}

// 管理员修改用户信息
export function changeUser(data) {
  return request.post('/psychic/admin/change/user', data)
}

// 审核订单评论
export function reviewComment(data) {
  return request.post('/psychic/admin/review/comment', data)
}

// 顾问信息审核
export function getStarAuditList(data) {
  return request.get('/psychic/admin/modify/star_info/list', {
    params: data
  })
}
export function auditStar(data) {
  return request.post('/psychic/admin/modify/star_info/review', data)
}

// 消息屏蔽
export function blockStar(data) {
  return request.post('/psychic/admin/block/star', data)
}
export function unBlockStar(data) {
  return request.post('/psychic/admin/unblock/star', data)
}

///管理员条件拉取订单评论
export function getComments(data) {
  return request.post('/psychic/admin/search/comments', data)
}

export function declineCashout(data) {
  return request.post('/psychic/admin/decline/cashout', data)
}

export function finishCashout(data) {
  return request.post('/psychic/admin/finish/cashout', data)
}

export function getCashouts(data) {
  return request.post('/psychic/admin/cashout/records', data)
}

export function rewardUser(data) {
  return request.post('/psychic/admin/reward/user', data)
}

// 充值列表
export function getIapTransactions(data) {
  return request.get('psychic/admin/purchase/list', {
    params: data
  })
}

export function getRewardList(data) {
  return request.get('/psychic/admin/reward/list', {
    params: data
  })
}

export function updateFeatureStar(data) {
  return request.post('/psychic/admin/feature/star', data)
}

export function updateStarHot(data) {
  return request.post('/psychic/admin/update/hots', data)
}

export function updateStarStatus(data) {
  return request.post('/psychic/admin/star/status', data)
}

export function updateStar(data) {
  return request.post('/psychic/admin/change/user', data)
}

export function getFeatureStar(data) {
  return request.get('/psychic/admin/star/ranks', {
    params: data
  })
}

//user_id=${userId}&user_identify=star
export function getStarInfo(data) {
  return request.get(`/psychic/admin/user/who`, {
    params: data
  })
}

// 顾问绑定、修改手机号码
export function changeStarPhone(data) {
  return request.post('/psychic/admin/star/changephone', data)
}

export function getStarTags() {
  return request.get('/star/tags')
}

export function getApprovedStars() {
  return request.get('/psychic/admin/search/stars', {
    params: {
      countPerPage: 1000,
      approved: 1
    }
  })
}

export function getStars() {
  return request.get('/psychic/admin/search/stars', {
    params: {
      countPerPage: 2000,
      page: 1
    }
  })
}

// 顾问增加video
export function starAddVideo(data) {
  return request.post('/psychic/admin/star/addVideo', data)
}

// 顾问更新状态
export function starUpdateVideo(data) {
  return request.post('/psychic/admin/star/updateVideo', data)
}

// 获取所有的video
export function getVideos() {
  return request.get('/psychic/admin/star/getVideos')
}

// 删除video
export function starDeleteVideo(data) {
  return request.post('/psychic/admin/star/deleteVideo', data)
}

// 删除用户
export function delUser(data) {
  return request.post('/api/deluser', data)
}

// 获取用户列表
export function getAllUser() {
  return request.get('/api/alluser')
}

// 发送验证码
export function userVerify(data) {
  return request.post('/api/verify', data)
}
// 注册
export function userRegister(data) {
  return request.post('/api/register', data)
}

// /psychic/admin/search/purchase/records
//  管理员检索购买记录

export function searchPurchase(data) {
  return request.post('/psychic/admin/search/purchase/records', data)
}

// 上传图片
export function uploadFun(data) {
  return request.post('/media/url', data)
}

//检索订单
export function searchOrders(data) {
  return request.post('/psychic/admin/search/orders', data)
}

//获取顾问rank列表
export function getStarRank(data) {
  return request.get('/psychic/admin/star/ranks', {
    params: data
  })
}

//修改顾问rank列表
export function editStarRank(data) {
  return request.post('/psychic/admin/star/ranks', data)
}
//查询交易流水
export function creditRecords(data) {
  return request.post('/psychic/admin/search/credit/records', data)
}

//审核顾问回复的内容
export function commentReply(data) {
  return request.post('/psychic/admin/review/comment/reply', data)
}

// 查询奖励记录

export function searchAwards(data) {
  return request.post('/psychic/admin/search/user/awards', data)
}

//查询邀请记录
export function inviteRecords(data) {
  return request.post('/psychic/admin/search/invite/records', data)
}

//拉取rank配置
export function getRankConfig(data) {
  return request.get('/psychic/admin/star/ranks', {
    params: data
  })
}

//拉取项目配置

export function getWebConfig() {
  return request.get('/psychic/admin/config')
}

//根据关键词查找顾问
export function getStarByKey(data) {
  return request.get('/search/stars', {
    params: data
  })
}
//根据关键词查找用户
export function getUserByKey(data) {
  return request.post('/user/search', data)
}

// 拉取所有增值服务tab
export function getServiceRankTabs(data) {
  return request.get('/psychic/admin/premium/rank/types', { params: data })
}

// 增加一个tab类型
export function createServiceTab(data) {
  return request.post('/psychic/admin/premium/rank/create', data)
}

// 添加增值服务到tab
export function addServiceToTab(data) {
  return request.post('/psychic/admin/premium/rank/add', data)
}

// 按tab查询增值服务
export function getServicesByTab(data) {
  return request.get('/psychic/admin/premium/rank/get', { params: data })
}

//查询增值服务
export function getServices(data) {
  return request.post('/psychic/admin/search/premium/services', data)
}

// 增值服务添加cateagory
export function addCategories(data) {
  return request.post('/psychic/admin/premium/addCategory', data)
}

// 按category查询增值服务
export function getPremiumServicesByCategory(data) {
  return request.get('/psychic/admin/premium/getByCategory', { params: data })
}

// 审核增值服务
// 审核增值服务，approved字段 0 带审核 1 审核通过 2 审核不通过
export function premiumService(data) {
  return request.post('/psychic/admin/review/premium/service', data)
}

//获取订单聊天记录
// admin/chat/messages
export function chatMessages(data) {
  return request.get('admin/chat/messages', {
    params: data
  })
}

//导出付费用户
// /psychic/admin/paying/user/report
export function exportPayingUser(data) {
  return request.get('/psychic/admin/paying/user/report', {
    params: data
  })
}

// 新增系统公告
export function addNotice(data) {
  return request.post('/psychic/admin/system/notice', data)
}

export function deleteNotice(data) {
  return request.delete('/psychic/admin/system/notice', {
    data: data
  })
}

///psychic/admin/search/system/notices
export function searchSystemNotices(data) {
  return request.post('/psychic/admin/search/system/notices', data)
}

//导出邀请记录
export function inviteExport(data) {
  return request.get('/psychic/admin/invite/record/report', {
    params: data
  })
}

export function exportExcel(data, url) {
  return request.get(url, {
    params: data
  })
}

// /psychic/admin/comb/star/ranks
//管理员组合rank查询
export function adminCombStarRanks(data) {
  return request.get('/psychic/admin/comb/star/ranks', {
    params: data
  })
}

// /psychic/admin/tag/delete
// 删除标签组
export function adminDeleteTag(data) {
  return request.post('/psychic/admin/tag/delete', data)
}

//给用户添加标签

// /psychic/admin/tag/users
//添加标签
export function adminTagUSers(data) {
  return request.post('/psychic/admin/tag/users', data)
}

// /psychic/admin/user/tags
//查询用户标签
export function adminUserTags(data) {
  return request.get('/psychic/admin/user/tags', {
    params: data
  })
}

///psychic/admin/untag/users取消用户标签
export function adminUntagUSers(data) {
  return request.post('/psychic/admin/untag/users', data)
}

// /psychic/admin/search/tag/users
//根据标签查询用户
export function searchTagUsers(data) {
  return request.post('/psychic/admin/search/tag/users', data)
}

// /psychic/admin/search/user/notes

export function userNotes(data) {
  return request.post('/psychic/admin/search/user/notes', data)
}

//查看birth chart记录
export function birthChartRecord(data) {
  return request.get('/psychic/admin/birthchart/record', {
    params: data
  })
}

//修改birth chart记录
export function birthchartUpdate(data) {
  return request.post('/psychic/admin/birthchart/update', data)
}

//管理员发放奖励
export function adminBulkReward(data) {
  return request.post('/psychic/admin/bulk/reward', data)
}

export function getLeaderBoard(product) {
  return request.get('admin/star/leaderboard', {
    headers: {
      'user-info': product
    }
  })
}

export function articleCreate(data) {
  return request.post('/psychic/admin/article/create', data)
}
export function articleUpdate(data) {
  return request.post('/psychic/admin/article/update', data)
}
export function getArticleList(data) {
  return request.get('/article/list', {
    params: data
  })
}
export function getArticle(data) {
  return request.get('/article/get', {
    params: data
  })
}

export function spiritrueCreate(data) {
  return request.post('/psychic/admin/article/spiritrue/create', data)
}
export function spiritrueUpdate(data) {
  return request.post('/psychic/admin/article/spiritrue/update', data)
}
export function getSpiritrueList(data) {
  return request.get('/psychic/admin/article/spiritrue/list', {
    params: data
  })
}
export function getSpiritrue(data) {
  return request.get('/psychic/admin/article/spiritrue/get', {
    params: data
  })
}

export function getChat() {
  return request.get('/psychic/admin/guidance/chat')
}
export function guidanceList(data) {
  return request.get('/psychic/admin/guidance/list', {
    params: data
  })
}
export function guidanceReply(data) {
  return request.post('/psychic/admin/guidance/reply', data)
}

export function journeyList(data) {
  return request.get('/psychic/admin/journey/list', {
    params: data
  })
}

export function journeyApproved(data) {
  return request.post('/psychic/admin/journey/approved', data)
}

export function journeyCreate(data) {
  return request.post('/psychic/admin/journey/create', data)
}

export function journeyDelete(data) {
  return request.post('/psychic/admin/journey/delete', data)
}

export function premiumServiceComments(data) {
  return request.get('/psychic/admin/premium/comments', {
    params: data
  })
}

export function adminNoteCreate(data) {
  return request.post('/psychic/admin/psychic/adminnote/create', data)
}

// 获取某个月的fearture
export function getFeatureStars(data) {
  return request.get('/psychic/admin/star/features', {
    params: data
  })
}

// 更新、创建一个月的fearture
export function updateMonthFeature(data) {
  return request.post('/psychic/admin/star/features', data)
}

// 查询用户邮箱关闭状态
export function getUserEmailDelStatus(data) {
  return request.get('/psychic/admin/email/blacklist/list', {
    params: data
  })
}

// 更新用户邮箱关闭状态
export function updateUserEmailDelStatus(data) {
  return request.post('/psychic/admin/email/blacklist/add', data)
}

// 导出用户和顾问聊天记录
export function exportChatRecords(data) {
  return request.get('/psychic/admin/xmpp/message/records', {
    params: data
  })
}

// 查询顾问订单完成情况
export function getStarOrders(data) {
  return request.get('/psychic/admin/star/order/summary', {
    params: data
  })
}

// 查询顾问惩罚情况
export function getStarPunish(data) {
  return request.get('/psychic/admin/star/punish/record', {
    params: data
  })
}

// 3min奖励发放列表获取
export function get3minRewards(data) {
  return request.get('/psychic/admin/mins3/award/list', {
    params: data
  })
}

// 3min奖励发放
export function send3minReward(data) {
  return request.post('/psychic/admin/mins3/award/got', data)
}

// 查询流失用户记录
export function getLossUserRecords(data) {
  return request.get('/psychic/admin/churn/record', { params: data })
}

// 流失用户发奖励
export function sendLossUserReward(data) {
  return request.post('/psychic/admin/churn/reward', data)
}

// 查询public reading square
export function getReadingSquare(data) {
  return request.get('/psychic/admin/order/public/list', { params: data })
}

// 审核public reading square
export function readingSquareApprove(data) {
  return request.post('/psychic/admin/order/public/update', data)
}

// 退款记录查询
export function searchRefund(data) {
  return request.post('/psychic/admin/search/refund/records', data)
}

// 增值服务订单增加时长，修改退款状态
export function orderUpdate(data) {
  return request.post('/psychic/admin/order/update', data)
}

// 获取所有后台管理用户，钉钉用户
export function getAllDDUsers(data) {
  return request.get('/permission/users', { params: data })
}

// 修改钉钉用户权限
export function updateAdminPerm(data) {
  return request.post('/permission/update', data)
}

// 删除钉钉用户
export function deleteDDUser(data) {
  return request.post('/permission/user/delete', data)
}

// 生成token
export function createToken(data) {
  return request.post('/psychic/admin/token/create', data)
}

// 删除评论
export function delComment(data) {
  return request.post('/psychic/admin/review/comment/delete', data)
}

// 查询顾问
export function searchStars(data) {
  return request.get('/psychic/admin/search/stars', { params: data })
}

// 给用户加折扣
export function addDiscount(data) {
  return request.post('/psychic/admin/coupon/create', data)
}

// 模拟用户退款
export function simulateRefund(data) {
  return request.post('/psychic/admin/simulation/refund', data)
}

// 用户购买补单
export function makeupPurchase(data) {
  return request.post('/psychic/admin/make/up/purchase', data)
}

// 用户折扣券查询
export function getCouponList(data) {
  return request.get('/psychic/admin/coupon/list', { params: data })
}

// 根据订单ID查详情
export function getOrderInfo(data) {
  return request.get('/psychic/admin/order/one', { params: data })
}

// 查询同设备是否存在顾问
export function isTheSameStar(data) {
  return request.get('/psychic/admin/device/same', { params: data })
}

// 查询公告牌列表
export function getBoardList(data) {
  return request.get('/psychic/admin/insight_board/list', { params: data })
}

// 添加公告牌
export function createBoard(data) {
  return request.post('/psychic/admin/insight_board/create', data)
}

// 更新公告牌
export function updateBoard(data) {
  return request.post('/psychic/admin/insight_board/update', data)
}

// 查询单个公告牌
export function getBoardById(data) {
  return request.get('/psychic/admin/insight_board/get', { params: data })
}

// 给用户发push
export function pushToUser(data) {
  return request.post('/psychic/admin/push', data)
}

// 查询用户评论修改记录
export function getComModifyRecords(data) {
  return request.get('/psychic/admin/review/comment/history', { params: data })
}

// 同步顾问历史提现记录
export function synchronizeCashoutHistory() {
  return request.get('/payout/sync/cashout/history')
}

// 同步iap历史记录
export function synchronizeIapHistory() {
  return request.post('/summary/sync/iap/history')
}
