<template>
  <div class="footer">Psychic Admin Tool by Dawson. 2020</div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.footer {
  height: 50px;
  line-height: 50px;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #777;
}
</style>
