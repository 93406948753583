import { createStore } from 'vuex'
import app from './modules/app'
import user from './modules/user'
import payout from './modules/payout'
import account from './modules/account'
import permission from './modules/permission'
import getters from './getters'

const store = createStore({
  modules: {
    app,
    user,
    payout,
    account,
    permission
  },
  getters
})

export default store
