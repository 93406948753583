<template>
  <div class="header">
    <div @click="handleMenu" class="menu">
      <el-icon size="25px">
        <Menu />
      </el-icon>
    </div>
    <div class="logo">Light Finance</div>
    <div class="navbar"></div>
  </div>
</template>

<script>
// import copy from 'copy-to-clipboard'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Header',
  data() {
    return {
      menuBtn: 'el-icon-s-fold',
      tokenVisible: false,
      bindUserList: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['sidebar']),
    // 如果左侧菜单打开，则旋转btn180度
    isActive() {
      return !this.sidebar.opened
    }
  },
  created() {
    try {
      this.bindUserList = JSON.parse(localStorage.getItem('bindUserInfo')) || []
    } catch (e) {
      console.error(e)
      this.bindUserList = []
    }
  },
  methods: {
    ...mapMutations({
      bindLogout: 'BIND_LOGOUT'
    }),
    handleMenu() {
      this.$store.dispatch('ToggleSideBar')
    },
    logout() {
      this.$confirm('是否退出账户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.bindLogout()
          this.$router.push({
            path: '/login'
          })
        })
        .catch()
    }
  }
}
</script>

<style scoped lang="scss">
.admin-list {
  li {
    margin: 10px 0;
    border-bottom: 1px solid #eeeeee;
    &:last-child {
      border: none;
    }
  }
}
.menu {
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
}
.header {
  width: 100%;
  height: 50px;
  display: flex;
  background: #36a9e1;

  .logo {
    width: 200px;
    height: 50px;
    background: #36a9e1;
    color: #fff;
    text-indent: 15px;
    text-align: start;
    font-size: 18px;
    line-height: 50px;
    font-weight: 600;
    transition: 0.4s ease;

    &.is-active {
      width: 64px;
    }

    img {
      padding: 0 5px 5px 0;
    }
  }

  .navbar {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: #fff;
    position: relative;

    .btn {
      height: 50px;
      line-height: 50px;
      cursor: pointer;
      transition: 0.4s ease;

      &.is-active {
        transform: rotateY(180deg);
      }

      span {
        font-size: 28px;
        line-height: 50px;
        transition: 0.5s;
        color: #ffffff;
        font-weight: 400;
      }
    }

    .el-dropdown-link {
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 768px) {
  .header .logo.is-active {
    width: 0;
    overflow: hidden;
  }
}
</style>
